import React, { useState, useEffect } from "react";
import {
  getWishCart,
  addToWishlist,
  getShopingCart,
  checkoutWishlist,
} from "./../services/CartServices";
import Product from "./../components/ProductWish";
import wishlisticon from "./icons/wishlisticon.svg";
import "./style.css";
import "./checkout.css";
import "./empty.css";

function Empty({ history, match }) {
  const [wishCount, setWishCount] = useState(0);
  const [applyCuponClicked, setApplyCuponClicked] = useState(false);
  const [cuponStep, setCuponStep] = useState(0);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isemptyCart, setIsemptyCart] = useState(null);
  const [productsExpand, setProductsExpand] = useState([]);
  const [expand, setExpand] = useState(false);
  const [total, setTotal] = useState(0);
  const [deliveryCharge, setDeliveryCharge] = useState(120);
  const [tax, setTax] = useState(49);

  
  function spliceWishCart(items = []) {
    if (items.length > 2) {
      let length = items.length - 2;
      items.splice(2, length);
      console.log("Products: ", items);
    }
    return items;
  }

  function sum(cartItems) {
    if (cartItems.length) {
      let prices = [];
      cartItems.map((item) => prices.push(item.price));
      let totalPrice = prices.reduce(function (previousValue, currentValue) {
        return parseInt(previousValue) + parseInt(currentValue);
      });
      setTotal(totalPrice);
    }
  }

  function animateButton(e) {
    e.preventDefault();
    setApplyCuponClicked(false);

    setApplyCuponClicked(true);
    setTimeout(function () {
      setApplyCuponClicked(false);
    }, 700);
  }

  async function getWishCartItems() {
    let wishlist = getWishCart(match.params.chatId);
    let wishlistItems = (await wishlist).products;
    setWishCount(wishlistItems.length);
    return wishlistItems;
  }

  async function getwishlist() {
    try {
      let wishlistItems = await getWishCartItems();
      console.log("Products: ", wishlistItems);
      setProducts(spliceWishCart(wishlistItems));
      setExpandItems();
    } catch (error) {
      console.log(error);
    }
  }

  async function setExpandItems() {
    let cartItems = await getWishCartItems();
    console.log("Expand items: ", cartItems);
    if (cartItems.length) {
      sum(cartItems);
    }
    setProductsExpand(cartItems);
  }

  function update(price, type, index = null) {
    let totalPrice;
    if (type == "add") {
      totalPrice = Number(total) + Number(price);
      products[index].amount = products[index].amount + 1;
      productsExpand[index].amount = productsExpand[index].amount + 1;
    } else {
      totalPrice = Number(total) - Number(price);
      products[index].amount = products[index].amount - 1;
      productsExpand[index].amount = productsExpand[index].amount - 1;
    }
    setTotal(totalPrice);
  }

  function totalPrice() {
    return parseInt(Number(total + deliveryCharge + tax).toString());
  }

  function selectProduct(selected, product){
    if(selected){
      selectedProducts.push(product)
      return
    }
    setSelectedProducts(selectedProducts.filter(selectedProduct => selectedProduct != product))
  }

  function renderProducts() {
    let items = expand ? productsExpand : products;
    return items.map((product, i) => {
      return (
        <Product
          key={i}
          name={product.name}
          price={product.price}
          image={product.image}
          wishList={true}
          updateCart={getwishlist}
          addToWishlist={addToWishlist}
          product_id={product.product_id}
          category={product.category}
          amount={product.amount}
          update={update}
          chatId={match.params.chatId}
          index={i}
          product={product}
          selectProduct={selectProduct}
        />
      );
    });
  }

  async function checkCart() {
    try {
      let cart = await getShopingCart(match.params.chatId);
      if (cart.products.length > 0) {
        setIsemptyCart(false);
      } else {
        setIsemptyCart(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function checkoutWishCart() {
    if(selectedProducts.length > 0){
      checkoutWishlist(
        {
          wishlist: JSON.stringify({
            products: selectedProducts,
          }),
          sender_id: match.params.chatId,
        },
        (res) => {
          console.log(res)
            if(res.message == "Success"){
              history.push(`/checkout/${match.params.chatId}?wishlist=true`);
            }
        }
      );
    }
  }

  useEffect(() => {
    getwishlist();
    sum(products);
    checkCart();
  }, []);

  return (
    <React.Fragment>
      <header>
        <div className="container-fluid d-flex flex-row">
          <div className="cart">
            <i className="header-icon fa fa-shopping-cart"></i>
          </div>
          <p className="cart_text">Shopping cart</p>
          <div className="separator"></div>

          <div className="checkout">
            <i className="header-icon fa fa-truck"></i>
          </div>
          <p className="">Checkout</p>
          <div className="separator"></div>

          <div className="finish">
            <i className="header-icon fa fa-check"></i>
          </div>
          <p className="">Finish</p>
          <div className="separator"></div>

          <div>
            <img className="ml-auto logo" src={wishlisticon} alt="" onClick={()=> history.push(`/${match.params.chatId}`)}/>
            <p className={"wish-count"}>{wishCount}</p>
          </div>
        </div>
      </header>

      {isemptyCart && (
        <section className="empty">
          <div className="container text-center">
            <img src="/images/empty_cart.png" alt="" />
            <p className="m-t-10">Shopping cart is empty</p>
          </div>
        </section>
      )}

      {products.length > 0 && (
        <section className="product">
          <div className="container">
            <h1>Wishlisted Products</h1>
          </div>

          {renderProducts()}
          {
            <div className="load_more" onClick={() => setExpand(!expand)}>
              <img src="/images/load_more.png" alt="" />
            </div>
          }
        </section>
      )}

      {products.length > 0 && (
        <section className="checkout_section">
          <div className="container">
            <div className="coupon text-center">
              {cuponStep === 0 && (
                <button
                  type="button"
                  className={`bubbly-button m-t-10 m-b-10 ${
                    applyCuponClicked ? "animate" : " "
                  }`}
                  onClick={(e) => {
                    animateButton(e);
                    setTimeout(function () {
                      setCuponStep(1);
                    }, 700);
                  }}
                >
                  <img className="m-r-10" src="/images/percent.png" alt="" />
                  Apply Coupon
                  <img className="m-l-10" src="/images/arrow.png" alt="" />
                </button>
              )}

              {cuponStep === 1 && (
                <div
                  className={`form_custom d-flex flex-row `}
                  style={{ marginTop: 30, marginBottom: 40 }}
                >
                  <div className="form-group m-r-5 m-l-10 m-t-11">
                    <label className="sr-only">Enter coupon</label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputCoupon"
                      placeholder="Enter coupon"
                      maxLength={5}
                    />
                  </div>
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      setTimeout(function () {
                        setCuponStep(2);
                      }, 400)
                    }
                  >
                    Apply
                  </button>
                </div>
              )}

              {cuponStep === 2 && (
                <button
                  type="button"
                  className="bubbly-button m-t-10 m-b-10"
                  disabled
                >
                  Coupon Applied
                  <img className="m-l-10" src="/images/tick.png" alt="" />
                </button>
              )}
            </div>

            <div className="calculation">
              <div className="container">
                <div className="d-flex flex-row m-t-30">
                  <h5>Item Total</h5>
                  <h5 className="ml-auto">৳{total || "00"}.00</h5>
                </div>
                <div className="d-flex flex-row m-t-20">
                  <h5>Delivery Charge</h5>
                  <h5 className="ml-auto">
                    ৳{products.length ? deliveryCharge : "00"}
                  </h5>
                </div>
                <div className="d-flex flex-row m-t-20">
                  <h5>Tax</h5>
                  <h5 className="ml-auto">৳{products.length ? tax : "00"}</h5>
                </div>
                <div className="d-flex flex-row m-t-20">
                  <h4>Total : </h4>
                  <h4 className="ml-auto">
                    ৳{products.length ? totalPrice() || "00" : "00"}.00
                  </h4>
                </div>

                <div className="checkout_button text-center m-t-30 m-b-20">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      checkoutWishCart();
                    }}
                  >
                    Checkout
                    <img className="m-l-10" src="/images/cart_2.png" alt="" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </React.Fragment>
  );
}

export default Empty;
