import React, { useState, useEffect } from "react";
import {modifyCart} from './../services/CartServices'

function Product({
  cart_id,
  name,
  price,
  image,
  wishList,
  addToWishlist,
  updateCart,
  product_id,
  category,
  amount,
  update,
  chatId
}) {
  const [quantity, setQuantity] = useState(amount);

  async function updateItem(type) {
    console.log(type)
    if (type == "del") {
      modifyCart(product_id, chatId, '-', (data)=>{
        if(data.success){
          setQuantity(quantity - 1);
          update(price, "del")
        }
      })
    } else {
      modifyCart(product_id, chatId, '+', (data)=>{
        if(data.success){
          setQuantity(quantity + 1);
          update(price, "add")
        }
      })
    }

  }

  return (
    <div className="item">
      <div className="container">
        <div className="row">
          <div className="col-4">
            <div className="product_img">
              <img src={image} alt="" />
            </div>
          </div>
          <div className="col-4">
            <div className="product_middle">
              <div className="product_title">
                <h5>{name}</h5>
              </div>
              <h6>Brand: {category}</h6>
              <div id="field1" className="">
                <button
                  type="button"
                  id="sub"
                  className="sub"
                  onClick={() => {
                    if (quantity > 1) {
                      updateItem("del")
                    }
                  }}
                >
                  <i className="fa fa-minus-circle"></i>
                </button>
                <input
                  disabled
                  className="val"
                  type="number"
                  id="1"
                  value={quantity}
                  min="0"
                  max="900"
                  onChange={(e) => {
                    if (quantity > 1) {
                      setQuantity(e.target.value);
                    }
                  }}
                />
                <button
                  style={{ position: 'relative', right: 16 }}
                  type="button"
                  id="add"
                  className="add"
                  onClick={() => {
                    if (quantity > 0) {
                    updateItem("add")
                    console.log(quantity)
                    }
                  }}
                >
                  <i className="fa fa-plus-circle"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="col-4">
            <div className="product_right">
              <h5>৳{price * quantity}</h5>
              <div className="d-flex flex-row product_color">
                <div className="m-r-5">
                  <h6>Color: </h6>
                </div>
                <div className="">
                  <select name="cars" id="color" className="custom-select">
                    <option value="">Blue</option>
                    <option value="">Red</option>
                    <option value="">Black</option>
                    <option value="">Green</option>
                  </select>
                </div>
              </div>
              <div className="d-flex flex-row">
                <div
                  className="m-r-5"
                  onClick={() =>
                    addToWishlist(
                      chatId,
                      wishList ? "del" : "add",
                      product_id,
                      (res) => {
                        if (res) {
                          updateCart();
                        }
                      }
                    )
                  }
                >
                  <p>{wishList ? "Remove wishlist" : "Add to Wishlist"}</p>
                </div>
                <div
                  className=""
                  onClick={() =>
                    addToWishlist(
                      chatId,
                      wishList ? "del" : "add",
                      product_id,
                      (res) => {
                        if (res) {
                          console.log(res)
                          updateCart();
                        }
                      }
                    )
                  }
                >
                  <i className={wishList ? "fa fa-heart" : "fa fa-heart-o"}></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Product;
