import React, { useState, useEffect } from "react";
import { countries, getCitiesByCountryCode } from "country-city-location";
import { checkoutCart, getWishCart } from "./../services/CartServices";
import { finished } from "stream";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import queryString from "query-string";
import wishlisticon from "./icons/wishlisticon.svg";

import "./style.css";
import "./checkout.css";
import "./empty.css";

//All months name
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

//Upcoming Years
const years = [
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
  "2025",
  "2026",
  "2027",
  "2028",
  "2029",
  "2030",
];

function Checkout({ match, location, history }) {
  const [wishCount, setWishCount] = useState(0);
  const [country, setCountry] = useState(null);
  const [currency, setcurrency] = useState(null);
  const [city, selectCity] = useState(null);
  const [filteredCities, setCities] = useState(getCitiesByCountryCode("AF"));
  const [first_name, setfirst_name] = useState(null);
  const [last_name, setlast_name] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [address, setAddress] = useState(null);
  const [zip, setZip] = useState(null);
  const [card_number, setcard_number] = useState(null);
  const [expMonth, setExpMonth] = useState(null);
  const [expYear, setexpYear] = useState(null);
  const [security_code, setsecurity_code] = useState(null);
  const [payment_method, setPayment_method] = useState(null);

  const parsedQs = queryString.parse(location.search);

  //Select country
  function selectCountry(countryCode) {
    let fetchedCities = getCitiesByCountryCode(countryCode);
    console.log("city found", fetchedCities);
    setCities(fetchedCities);
  }

  //Validate Email
  function validateEmail(mail) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(mail);
  }

  //Validate Phone Number /**Bangladesh*/
  function validatePhone(number) {
    const re = /(^(\+8801|8801|01|008801))[1|3-9]{1}(\d){8}$/;
    return re.test(number);
  }

  /**
   * Submit Checkout Form
   */
  function finish() {
    if (
      first_name &&
      last_name &&
      validatePhone(phone) &&
      city &&
      country &&
      address &&
      zip &&
      payment_method
    ) {
      checkoutCart(
        match.params.chatId,
        {
          first_name,
          last_name,
          email,
          phone,
          city,
          country,
          address,
          card_number,
          postal_code: zip,
          expiration_date: `${expMonth}/${expYear}`,
          security_code,
          payment_method,
          currency,
          coupon_name: "EID 15%",
          coupon: 150,
          delivery_charge: 120,
          discount_charge: 30,
          tax_charge: 49,
          wishlist: parsedQs.wishlist ? true : false,
        },
        (response) => {
          console.log(response);
          if (response.success) {
            toast.success("Checkout successfull");
            return window.close();
          } else {
            toast.error("Something went wrong!");
          }
        }
      );
    } else {
      toast.error("Please input all fields before submit!");
    }
  }

  /**
   * Check Form is ready to finish
   */
  function isReady() {
    if (
      first_name &&
      last_name &&
      validatePhone(phone) &&
      city &&
      country &&
      address &&
      zip &&
      payment_method
    ) {
      if (
        payment_method == "card" &&
        ValidateCreditCardNumber(card_number) &&
        expMonth &&
        expYear &&
        security_code
      ) {
        return true;
      }
      if (payment_method == "cod") {
        return true;
      }
      return false;
    }
    return false;
  }

  /**
   * Validate Credit card number
   */
  function ValidateCreditCardNumber(ccNum) {
    var visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    var mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
    var amexpRegEx = /^(?:3[47][0-9]{13})$/;
    var discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
    var isValid = false;

    if (visaRegEx.test(ccNum)) {
      isValid = true;
    } else if (mastercardRegEx.test(ccNum)) {
      isValid = true;
    } else if (amexpRegEx.test(ccNum)) {
      isValid = true;
    } else if (discovRegEx.test(ccNum)) {
      isValid = true;
    }

    if (isValid) {
      return true;
    } else {
      return false;
    }
  }

  async function getWishCartItems() {
    let wishlist = getWishCart(match.params.chatId);
    let wishlistItems = (await wishlist).products;
    setWishCount(wishlistItems.length);
  }

  useEffect(() => {
    getWishCartItems();
  }, []);

  return (
    <React.Fragment>
      <header>
        <div className="container-fluid d-flex flex-row">
          <div className="cart">
            <i className="header-icon fa fa-shopping-cart"></i>
          </div>
          <p className="cart_text">Shopping cart</p>
          <div className="separator"></div>

          <div className="cart">
            <i className="header-icon fa fa-truck"></i>
          </div>
          <p className="">Checkout</p>
          <div className="separator"></div>

          <div className={isReady() ? "cart" : "finish"}>
            <i className="header-icon fa fa-check"></i>
          </div>
          <p className="">Finish</p>
          <div className="separator"></div>

          <div>
            <img
              className="ml-auto logo"
              src={wishlisticon}
              alt=""
              onClick={() => history.push(`/${match.params.chatId}`)}
            />
            <p className={"wish-count"}>{wishCount}</p>
          </div>
        </div>
      </header>

      <section className="shipping_details m-t-20">
        <div className="container">
          <h2>Shipping details</h2>

          <div className="form-group">
            <div className="row">
              <div className="col-6">
                <label>First name</label>
                <input
                  type="text"
                  className="form-control"
                  id="first_name"
                  aria-describedby="emailHelp"
                  placeholder="First Name"
                  onChange={(e) => setfirst_name(e.target.value)}
                />
              </div>
              <div className="col-6">
                <label>Last name</label>
                <input
                  type="text"
                  className="form-control"
                  id="last_name"
                  aria-describedby="emailHelp"
                  placeholder="Last Name"
                  onChange={(e) => setlast_name(e.target.value)}
                />
              </div>
            </div>
            <div className="row m-t-5">
              <div className="col-6">
                <label>
                  Phone{" "}
                  {phone && !validatePhone(phone) && (
                    <span style={{ color: "red", fontWeight: "normal" }}>
                      Invalid phone number
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  aria-describedby="emailHelp"
                  placeholder="Phone"
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  value={phone}
                />
              </div>
              <div className="col-6">
                <label>
                  Email (optional){" "}
                  {email && !validateEmail(email) && (
                    <span style={{ color: "red", fontWeight: "normal" }}>
                      Invalid email
                    </span>
                  )}
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="row m-t-5">
              <div className="col-12">
                <label>Address</label>
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  aria-describedby="emailHelp"
                  placeholder="Address"
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </div>

            <div className="row m-t-5">
              <div className="col-4">
                <label>Country</label>
                <select
                  className="form-control"
                  id="country"
                  onChange={(e) => {
                    let selectedCountry = JSON.parse(e.target.value);
                    setCountry(selectedCountry.Alpha2Code);
                    selectCountry(selectedCountry.Alpha2Code);
                    setcurrency(selectedCountry.CurrencyCode);
                  }}
                >
                  <option>Select</option>

                  {countries.map((country, index) => {
                    return (
                      <option value={JSON.stringify(country)} key={index}>
                        {country.Name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-4">
                <label>City</label>
                <select
                  className="form-control"
                  id="city"
                  onChange={(e) => selectCity(e.target.value)}
                >
                  <option>Select</option>
                  {filteredCities.map((city, index) => {
                    return (
                      <option value={city.name} key={index}>
                        {city.name}
                      </option>
                    );
                  })}
                  {!filteredCities.length && <option>No city</option>}
                </select>
              </div>
              <div className="col-4">
                <label>ZIP/Postal code</label>
                <input
                  type="email"
                  className="form-control"
                  id="zip"
                  aria-describedby="emailHelp"
                  placeholder="ZIP/Postal code"
                  onChange={(e) => setZip(e.target.value)}
                />
              </div>
            </div>
          </div>

          <h2 className="m-t-40">Payment method</h2>

          <div className="form-group m-t-20">
            <div className="row m-t-10">
              <div className="col-12 card_radio_wrap">
                <input
                  type="radio"
                  className="card_payment_radio"
                  id="card_payment_radio"
                  name="payment"
                  value="card"
                  onChange={(e) => setPayment_method(e.target.value)}
                />

                <input
                  disabled
                  type="text"
                  className="form-control"
                  id=""
                  aria-describedby="emailHelp"
                  placeholder="Credit/Debit card"
                />
                <img className="visa_img" src="/images/visa.png" alt="" />
                <img
                  className="master_card_img"
                  src="/images/master_card.png"
                  alt=""
                />
                <img
                  className="american_express_img"
                  src="/images/american_express.png"
                  alt=""
                />
                <img
                  className="discover_img"
                  src="/images/discover.png"
                  alt=""
                />
              </div>
              {payment_method === "card" && (
                <div className="col-12 card_input_wrap m-t-5">
                  <label>
                    Card number{" "}
                    {card_number && !ValidateCreditCardNumber(card_number) && (
                      <span style={{ color: "red", fontWeight: "normal" }}>
                        Invalid card number
                      </span>
                    )}
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="card"
                    aria-describedby="emailHelp"
                    placeholder="Card number"
                    onChange={(e) => setcard_number(e.target.value)}
                  />
                  <img
                    className="master_card_img"
                    src="/images/master_card.png"
                    alt=""
                  />
                </div>
              )}
            </div>
            {payment_method === "card" && (
              <div className="row m-t-5">
                <div className="col-4">
                  <label>Expiration date</label>
                  <select
                    className="form-control"
                    id="expiration"
                    onChange={(e) => setExpMonth(e.target.value)}
                  >
                    <option>Month</option>
                    {months.map((month, i) => (
                      <option value={i + 1} key={i}>
                        {month}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-3">
                  <label className="invisible">Year</label>
                  <select
                    className="form-control"
                    id="year"
                    onChange={(e) => setexpYear(e.target.value)}
                  >
                    <option>Year</option>
                    {years.map((year, i) => (
                      <option value={year.substr(year.length - 2)} key={i}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-3">
                  <label>Security code</label>
                  <input
                    type="password"
                    className="form-control"
                    id="security"
                    aria-describedby="emailHelp"
                    placeholder="Four digits"
                    onChange={(e) => setsecurity_code(e.target.value)}
                    maxLength={4}
                  />
                </div>
                <div className="col-2">
                  <i className="fa fa-question-circle"></i>
                </div>
              </div>
            )}

            <div className="row m-t-20">
              <div className="col-12 card_radio_wrap">
                <input
                  type="radio"
                  className="card_payment_radio"
                  id="cod_payment_radio"
                  name="payment"
                  value="cod"
                  onChange={(e) => setPayment_method(e.target.value)}
                />

                <input
                  type="text"
                  className="form-control"
                  aria-describedby="emailHelp"
                  placeholder="COD (Cash on Delivery)"
                  disabled
                />

                <img className="cod_img" src="/images/cod.png" alt="" />
              </div>
            </div>
          </div>

          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id="defaultCheck1"
            />
            <label className="form-check-label">
              By clicking the button, you agree to the{" "}
              <span>Terms and Conditions</span>
            </label>
          </div>

          <div className="checkout_button text-center m-t-30 m-b-20">
            <button className="btn btn-primary" onClick={() => finish()}>
              Finish
            </button>
          </div>
        </div>
      </section>
      <ToastContainer />
    </React.Fragment>
  );
}

export default Checkout;
