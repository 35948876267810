import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Empty from "./screens/Empty";
import Cart from "./screens/Cart";
import Checkout from "./screens/Checkout";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/:chatId" render={(props) => <Empty {...props} />} />
        <Route exact path="/cart/:chatId" render={(props) => <Cart {...props} />} />
        <Route exact path="/checkout/:chatId" render={(props) => <Checkout {...props} />} />
      </Switch>
    </Router>
  );
}

export default App;
