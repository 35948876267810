import { BASE_URL } from "./../env";

import axios from "axios";
interface cart {
  data: { data: { products: [] } };
}

export const getWishCart = async (chatId) => {
  let response: cart = await axios.get(`${BASE_URL}/wish-cart/${chatId}`);
  console.log(response.data.data);
  return response.data.data;
};

export const getShopingCart = async (chatId) => {
  let response: cart = await axios.get(
    `${BASE_URL}/shopping-cart-page/${chatId}`
  );
  console.log(response.data.data);
  return response.data.data;
};
const passCart = async (chatId) => {
  let response = await axios.get(`${BASE_URL}/pass-cart/${chatId}`);
  console.log("Pass cart", response.data);
  return response.data;
};

export const addToWishlist = async (chatId, type, product_id, callback) => {
  let response = await axios.post(
    `${BASE_URL}/${type == "add" ? "add-to" : "remove-from"}-wishList`,
    {
      sender_id: chatId,
      product_id,
    }
  );
  console.log(response.data.success);
  callback(response.data.success);
};

export const modifyCart = async (cart_id, chatId, sign, callback) => {
  let response = await axios.post(`${BASE_URL}/modify-cart/${chatId}`, {
    cart_id,
    sign,
  });
  console.log(response.data);
  callback(response.data);
};

export const checkoutCart = async (chatId, formData, callback) => {
  let cart = await passCart(chatId);
  let form = formData;
  form.cart = cart;
  form.total_charge = cart.total_price;

  console.log("Form: ", form);
  let response = await axios.post(`${BASE_URL}/create-receipt/${chatId}`, form);
  console.log("Checkout response: ", response.data);
  console.log("Cart: ", form);
  callback(response.data);
};

export const checkoutWishlist = async (data, callback) => {
  let response = await axios.post(`${BASE_URL}/checkout-wish-cart`, data);
  callback(response.data)
};
